import React from "react";
import { PAGE_TYPES } from "@customTypes/index";
import Head from "next/head";
import getConfig from "next/config";
import { STATIC_LANDING_PAGES_URLS } from "@components/utils/constants";
import { useRouter } from "next/router";
import { IBaseStrapiResponse } from "@customTypes/modelResponses";
import Script from "next/script";

export default function MetaTags({ ...data }): JSX.Element {
  const { publicRuntimeConfig } = getConfig();
  const router = useRouter();

  const endSlash = "/";
  let title: string | undefined =
    "Costa Rica Vacations | 100% local Costa Rica travel agency";
  let description: string | undefined = "";
  let canonical = "";
  let preventIndex = false;
  /**
   * Home page manually set data
   * packages will be populated only if you are on package.
   *
   */
  function metaData(seo: IBaseStrapiResponse["SEO"]): void {
    title = seo?.Meta_Title;
    // From the conversation with Jelena on 18.11.2022 - we don't need to respect this field for now.
    // preventIndex = seo?.SEO?.PreventIndex ?? false;
    description = seo?.Meta_Description;
    canonical = `${seo?.Slug}${endSlash}`;
  }

  function getBudgetQuery(): string {
    const { minBudget, maxBudget } = router.query;
    if (minBudget && maxBudget) {
      const parsedMinBudget = parseInt(minBudget as string);
      const parsedMaxBudget = parseInt(maxBudget as string);
      if (!isNaN(parsedMinBudget) && !isNaN(parsedMaxBudget)) {
        const queryAsString = `?minBudget=${parsedMinBudget}&maxBudget=${parsedMaxBudget}`;
        preventIndex = true;
        return queryAsString;
      }
    }
    return "";
  }

  switch (data.pageType) {
    case PAGE_TYPES.Home:
      metaData(data?.homePage?.SEO);
      canonical = ""; // This one should not end with endSlash.
      break;
    case PAGE_TYPES.Packages:
      metaData(data?.data?.SEO);
      canonical = `packages/${
        data?.data?.SEO?.Slug
      }${endSlash}${getBudgetQuery()}`;
      break;
    case PAGE_TYPES.Location:
      metaData(data?.location?.SEO);
      canonical += getBudgetQuery();
      break;
    case PAGE_TYPES.LandingPage:
      metaData(data?.landingPage?.SEO);
      break;
    case PAGE_TYPES.Accommodations:
      metaData(data?.accommodations?.SEO);
      break;
    case PAGE_TYPES.Activity:
      metaData(data?.activity?.SEO);
      break;
    case PAGE_TYPES.Page:
      metaData(data?.page?.SEO);
      break;
    case PAGE_TYPES.Article:
      metaData(data?.article?.SEO);
      break;
    case PAGE_TYPES.TravelConsultant:
      metaData(data?.travelConsultant?.SEO);
      canonical = `${STATIC_LANDING_PAGES_URLS.EXPERTS.substring(
        1
      )}${endSlash}${data?.travelConsultant?.SEO?.Slug}${endSlash}`;
      break;
    case PAGE_TYPES.Favorites:
      title = "Favorites";
      preventIndex = true;
      description = "View all Favorites";
      canonical = "favorites/";
      break;
    case PAGE_TYPES.ContactPage:
      metaData(data?.contactUs?.data?.attributes?.SEO);
      break;
    case PAGE_TYPES.PackageSearch:
      canonical = router.asPath;
      preventIndex = true; // we don't want to let the bots to index the search
      break;
    case PAGE_TYPES.InternalErrorPage:
      title = "Internal Server Error";
      preventIndex = true;
      break;
    case PAGE_TYPES.RedirectingPage:
      title = "Redirecting";
      preventIndex = true;
      break;
    default: // by default we should not get into this case, but still - let's keep it
      title = "Costa Rica Vacations | 100% local Costa Rica travel agency";
      description = "";
      canonical = "";
      preventIndex = false;
      break;
  }

  if (publicRuntimeConfig.WEB_URL.slice(-1) !== "/" && canonical !== "") {
    canonical = "/" + canonical;
  }
  canonical = canonical.replace("//", "/"); // remove the double slashes if we get any

  return (
    <Head>
      <Script strategy="beforeInteractive">{`window.prerenderReady = false;`}</Script>
      <title>{title}</title>
      <link rel="icon" type="image/x-icon" href="/favicon.ico" />
      <meta name="description" content={description} key="meta_description" />
      <meta property="og:title" content={title} key="title" />
      {preventIndex && (
        <meta name="robots" content="noindex" key="meta_robots" />
      )}
      <link
        rel="canonical"
        href={`${publicRuntimeConfig.WEB_URL}${canonical}`}
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=5"
        key="meta_viewport"
      />
      <link rel="preconnect" href="https://res.cloudinary.com" />
      <link rel="dns-prefetch" href="https://res.cloudinary.com" />
      <meta charSet="UTF-8" key="meta_charset" />
    </Head>
  );
}
